import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from './global';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    public translate: TranslateService,
    private global: Globals,
  ) {}

  setInitialAppLanguage(language: string) {
    this.translate.addLangs(['ru', 'kk'])
    if (!language) language = 'ru';
    this.translate.use(language);
    localStorage.setItem("language", language);
    this.global.language = language;
  }
}
