import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/helper/language.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  language: any;
  constructor(
    public translate: TranslateService,
    public LanguageService: LanguageService
  ) {

    this.language = localStorage.getItem('language');
    if (this.language === null) this.language = 'ru';
    this.LanguageService.setInitialAppLanguage(this.language);
    this.translate.use(this.language);
  }
}
