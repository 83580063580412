import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroModule } from './ng-zorro.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  imports: [CommonModule, ],
  exports: [
    TranslateModule, 
    NgZorroModule,
    ReactiveFormsModule
   
  ],
})
export class SharedModule {}
